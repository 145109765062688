import React, { useLayoutEffect } from 'react';
import Loadable from 'react-loadable';
import { useDispatch } from 'react-redux';
import { LoadingPage } from '@/components/loading/loadingBase';
import PublicProvider from '@/components/providers/PublicProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';
import AppAction from '@/reducers/app/action';
import { forceLogout } from '@/utils/auth';

interface FetchMailingPageProps {
  params: { id: string };
}

const FetchMailingLoadable = Loadable({
  loader: () => import('@/sections/Broker/Mailing'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const FetchMailingPage: React.FC<FetchMailingPageProps> = ({ params }) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    forceLogout(false);
    dispatch({
      type: AppAction.RESET_ROOT_STATE,
    });
  }, []);
  return (
    <React.Fragment>
      <HeaderMetadata title="Broker - Start to fetch mailing list" />
      <PublicProvider>
        <FetchMailingLoadable {...params} />
      </PublicProvider>
    </React.Fragment>
  );
};

export default FetchMailingPage;
